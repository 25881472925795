<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          Filtrele
          <span class="d-block text-muted pt-2 font-size-sm"></span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      
      <div class="mb-12">
        <DateRangePicker :key="dateRangePickerKey" :initial-date="{ start: initialDate.start, end: initialDate.end }" :max-date="maxDate" @change="datePickerChanged" />
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Durum</div>
        <div class="checkbox-list">
          <label v-for="item in states" :key="item.value" class="checkbox checkbox-pill font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light" :class="`checkbox-light-${item.color}`">
            <input type="checkbox" :value="item.value" v-model="checkedStates"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Tema</div>
        <div class="checkbox-list">
          <label v-for="item in themes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedThemes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      <div class="mb-12">
        <div class="text-muted mb-4 font-weight-bolder font-size-lg">Hizmet Tipi</div>
        <div class="checkbox-list">
          <label v-for="item in serviceTypes" :key="item.value" class="checkbox checkbox-pill checkbox-dark font-size-lg font-weight-bold py-3 rounded mb-0 pl-2 bg-hover-light">
            <input type="checkbox" :value="item.value" v-model="checkedServiceTypes"/>
            <span></span>
            {{ item.name }}
          </label>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment';
//import DateRangePicker from "@/view/content/datepicker/InlineDateRangePicker";
import DateRangePicker from "@/view/content/datepicker/DateRangePicker";

export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      checkedThemes: [],
      checkedServiceTypes: [],
      checkedStates: [],
      timeout: null,
      dateRangePickerKey: 0
    }
  },
  computed: {
    ...mapGetters(["experienceConfig"]),

    themes() {
      let arr = [];
      Object.entries(this.experienceConfig(`theme`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.tr
        });
      });
      return arr;
    },

    serviceTypes() {
      let arr = [];
      Object.entries(this.experienceConfig(`serviceType`)).forEach(([key, value]) => {
        arr.push({
          value: parseInt(key),
          name: value.tr
        });
      });
      return arr;
    },

    states() {
      let arr = [
        {
          value: 1,
          name: 'Onaylandı',
          color: 'success'
        },
        {
          value: 0,
          name: 'İptal Edildi',
          color: 'danger'
        }
      ];
      return arr;
    },

    initialDate() {
      const storage = this.openStorage();
      if (!storage) {
        return { start: null, end: null };
      } else {
        if (!storage.date) {
          return { start: null, end: null };
        } else {
          return { start: storage.date.values[0], end:storage.date.values[1] };
        }
      }
    },

    maxDate() {
      return moment().format('YYYY-MM-DD');
    }
  },
  mounted() {
    moment.locale('tr');
    this.init();
  },
  watch: {
    checkedThemes(val) {
      let arr = [];
      val.forEach((el) => {
        this.themes.find((theme) => {
          if (theme.value === el) {
            arr.push({value: el, name: theme.name});
          }
        })
      })
      this.updateFilter('themes', {groupName: 'Tema', values: val, items: arr});
    },
    checkedServiceTypes(val) {
      let arr = [];
      val.forEach((el) => {
        this.serviceTypes.find((serviceType) => {
          if (serviceType.value === el) {
            arr.push({value: el, name: serviceType.name});
          }
        })
      })
      this.updateFilter('serviceTypes', {groupName: 'Hizmet Tipi', values: val, items: arr});
    },
    checkedStates(val) {
      let arr = [];
      val.forEach((el) => {
        this.states.find((state) => {
          if (state.value === el) {
            arr.push({value: el, name: state.name});
          }
        })
      })
      this.updateFilter('states', {groupName: 'Durum', values: val, items: arr});
    }
  },
  methods: {
    init() {
      const storage = this.openStorage();
      if (!storage) {
        this.checkedThemes = [];
        this.checkedServiceTypes = [];
        this.checkedStates = [];
        this.updateFilter('date', {groupName: 'Tarih', values: [], items: []});
      } else {
        this.checkedThemes = storage.themes.values;
        this.checkedServiceTypes = storage.serviceTypes.values;
        this.checkedStates = storage.states.values;
      }
      this.dateRangePickerKey += 1;
    },
    datePickerChanged(dateRange) {
      let text = '';
      const startMY = moment(dateRange.start, 'YYYY-MM-DD').format('MMM YYYY');
      const startD = moment(dateRange.start, 'YYYY-MM-DD').format('D');
      const endMY = moment(dateRange.end, 'YYYY-MM-DD').format('MMM YYYY');
      const endD = moment(dateRange.end, 'YYYY-MM-DD').format('D');

      if (startMY === endMY) {
        if (startD === endD) {
          text = `${moment(dateRange.start, 'YYYY-MM-DD').format('D MMMM')}`;
        } else {
          text = `${startD} - ${moment(dateRange.end, 'YYYY-MM-DD').format('D MMMM')}`;
        }
      } else {
        text = `${moment(dateRange.start, 'YYYY-MM-DD').format('D MMM')} - ${moment(dateRange.end, 'YYYY-MM-DD').format('D MMM')}`;
      }

      this.updateFilter('date', {groupName: 'Tarih', values: [dateRange.start, dateRange.end], items: [{value: text, name: text}]});
    },
    openStorage () {
      return JSON.parse(sessionStorage.getItem('reservation-listings-filter'));
    },
    saveStorage (form) {
      sessionStorage.setItem('reservation-listings-filter', JSON.stringify(form));
    },
    updateFilter (input, value) {
      let storedFilter = this.openStorage();
      if (!storedFilter) storedFilter = {};
    
      storedFilter[input] = value;
      this.saveStorage(storedFilter);

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('changed');
      }, 750);
    }
  }
}
</script>

<style>

</style>