<template>
  <div class="d-flex flex-row flex-wrap align-items-center mb-5">
    <div v-for="(filter, key) in filters" :key="key">
      <AppliedFilterItem 
        v-for="(item, index) in filter.items" 
        :key="item.value"
        class="mr-5 mb-5"  
        :group="filter.groupName" 
        :item="item" 
        @removed="filterRemoved(key, index)"
      />
    </div>
  </div>
</template>

<script>
import AppliedFilterItem from "@/view/pages/reservation/components/listings/AppliedFilterItem";

export default {
  components: {
    AppliedFilterItem
  },
  computed: {
    filters() {
      return this.openStorage();
    }
  },
  methods: {
    openStorage () {
      return JSON.parse(sessionStorage.getItem('reservation-listings-filter'));
    },
    filterRemoved(key, index) {
      let filters = this.openStorage();
      let filterGroup = Object.assign({}, filters[key]);
      if (key === 'date') {
        filterGroup.values = [];
        filterGroup.items = [];
      } else {
        filterGroup.values.splice(index, 1);
        filterGroup.items.splice(index, 1);
      }
      filters[key] = filterGroup;
      this.saveStorage(filters);
      this.$emit('changed');
    },
    saveStorage (value) {
      sessionStorage.setItem('reservation-listings-filter', JSON.stringify(value));
    },
  }

}
</script>

<style>

</style>