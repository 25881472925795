<template>
  <div class="btn btn-icon btn-light w-auto btn-clean bg-hover-light border-0 d-inline-flex align-items-center btn-lg px-2">
    <button class="btn btn-icon btn-circle btn-xs btn-white bg-hover-secondary mr-3" @click="remove">
      <i class="ki ki-close icon-sm text-dark"></i>
    </button>
    <div class="d-flex flex-column align-items-start justify-content-center mr-2">
      <span class="text-muted font-weight-normal font-size-sm d-block line-height-sm pt-1">{{ group }}</span>
      <span class="text-dark-50 font-weight-bolder font-size-sm line-height-nm">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["group", "item"],
  methods: {
    remove() {
      this.$emit('removed');
    }
  }
}
</script>